<template>
    <div class="pt-120" id="case-islands">
            <div class="container">
               <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 m-auto order-1 order-lg-0">
                     <div class="ab-wrapper pos-rel">
                        <div class="ab-since">
                          <img src="../../assets/img/flaticon/013-floating-island.png" alt="">
                        </div>
                        <div class="row gx-2 mb-30">
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/case/islands-1.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/case/islands-2.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/case/islands-3.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/case/islands-4.jpg" alt="">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-6  order-0 order-lg-1">
                     <div class="ab-box pl-50 mb-30">
                        <div class="">
<!--                           <h5>Network Balancing</h5>-->
                           <h2 class="section-title">{{ $t('use_cases.islands.title') }}</h2>
                           <p style="text-align: justify" v-html="$t('use_cases.islands.desc1')"></p>
                           <p style="text-align: justify" v-html="$t('use_cases.islands.desc2')"></p>

                        </div>
                        <div class="ab-item-box mt-40 mb-30">
                           <div class="d-flex fix mb-20">
                              <div class="ab-flaticon f-left">
                                 <img src="../../assets/img/flaticon/012-solar-system.png"/>
                              </div>
                              <div class="fix my-auto">
<!--                                 <h3 class="ab-item-title">Immissione di valori elevati di Pcc</h3>-->
                                 <p class="m-0">{{ $t('use_cases.islands.item1') }}</p>
                              </div>
                           </div>
                           <div class="d-flex fix mb-20">
                              <div class="ab-flaticon f-left">
                                <img src="../../assets/img/flaticon/008-battery-charge.png"/>
                              </div>
                              <div class="fix my-auto">
<!--                                 <h3 class="ab-item-title">regolazione continua della frequenza</h3>-->
                                 <p class="m-0">{{ $t('use_cases.islands.item2') }}</p>
                              </div>
                           </div>
                           <div class="d-flex fix mb-20">
                              <div class="ab-flaticon f-left">
                                <img src="../../assets/img/flaticon/011-generator.png"/>
                              </div>
                              <div class="fix my-auto">
<!--                                 <h3 class="ab-item-title">regolazione continua della frequenza</h3>-->
                                 <p class="m-0">{{ $t('use_cases.islands.item3') }}</p>
                              </div>
                           </div>
                        </div>
<!--                        <div class="ab-btn">-->
<!--                           <router-link to="/about" class="tp-btn">Learn More</router-link>-->
<!--                        </div>-->
                     </div>
                  </div>
               </div>
            </div>
         </div>
</template>

<script>
export default {
    name:'IslandsUse',
}
</script>