<template>
  <header class="header-light">
    <div id="header-sticky" :class="`${isSticky ? 'header-area header-pad-2 sticky-2 sticky' : 'header-area header-pad-2 sticky-2'}`">
      <div class="container">
         <div class="row align-items-center">
            <div class="col-xl-3 col-lg-2 col-md-6 col-6">
               <div class="logo logo-border">
                  <router-link to="/"><img src="../assets/img/logo/logo-dark.png" alt="logo"></router-link>
               </div>
            </div>
            <div class="col-xl-9 col-lg-10 col-md-6 col-6 d-flex justify-content-end">
              <div class="main-menu text-center ">
                <nav id="mobile-menu">
                  <ul>
                    <li><router-link to="/" class="active"> Home </router-link></li>

                    <li><router-link to="/the-company"> {{ $t('header.company') }} </router-link></li>


                    <li>
                       <router-link to="/use-cases">{{ $t('header.use_cases') }}</router-link>
                       <ul class="sub-menu">
                         <li><router-link to="/use-cases/case-network-balancing">{{ $t('header.network_balancing') }}</router-link></li>
                         <li><router-link to="/use-cases/case-FER-power">{{ $t('header.fer') }}</router-link></li>
                         <li><router-link to="/use-cases/case-reactive-power">{{ $t('header.reactive_power') }}</router-link></li>
                         <li><router-link to="/use-cases/case-islands">{{ $t('header.islands') }}</router-link></li>
                         <li><router-link to="/use-cases/case-industrial-system">{{ $t('header.industrial_systems') }}</router-link></li>
                         <li><router-link to="/use-cases/case-railway-network">{{ $t('header.railway_networks') }}</router-link></li>
                         <li><router-link to="/use-cases/case-space-system">{{ $t('header.space_systems') }}</router-link></li>
                       </ul>
                    </li>

                    <li><router-link to="/the-patent">{{ $t('header.patent') }}</router-link></li>


                    <li>
                       <a><img v-bind:src="'/img/flags/' + $i18n.locale.toUpperCase() + '.png'" style="padding-right: 4px; height: 24px !important; width:auto !important;"></a>
                       <ul class="sub-menu">
                         <li v-for="lang in langs" v-bind:key="lang"
                             @click="changeLocale(lang)"
                             :set="langImg = '/img/flags/' + lang.toUpperCase() + '.png'">
                           <a href="#"><img v-bind:src="langImg" style="padding-right: 4px; height: 24px !important; width:auto !important;"> {{ lang.toUpperCase() }}</a>
                         </li>
                       </ul>
                    </li>


                  </ul>
                </nav>
              </div>
              <div class="header-right-info d-flex align-items-center justify-content-end">
<!--                        <div class="header-search" @click="handleSearch">-->
<!--                           <button class="search-toggle" type="button">-->
<!--                              <i class="fa fa-search"></i>-->
<!--                           </button>-->
<!--                        </div>-->
                <div class="sidebar__menu d-lg-none">
                   <div @click="menuOption.menuShow = !menuOption.menuShow"
                   class="sidebar-toggle-btn sidebar-toggle-btn-2 ml-30" id="sidebar-toggle">
                       <span class="line"></span>
                       <span class="line"></span>
                       <span class="line"></span>
                   </div>
               </div>

                <div class="header-btn d-none d-lg-block">
                   <router-link to="/contact-us" class="tp-btn">{{ $t('header.contact') }}</router-link>
                </div>
              </div>
            </div>
         </div>
      </div>
    </div>
  </header>


   <!-- search popup area start -->
<!--      <div :class="`${searchPopup ? 'search__popup transition-3 opened' : 'search__popup transition-3'}`">-->
<!--         <div class="search__popup-close">-->
<!--            <button type="button" class="search-popup-close-btn" @click="handleClose">-->
<!--               <i class="fal fa-times"></i>-->
<!--            </button>-->
<!--         </div>-->
<!--         <div class="search__popup-wrapper">-->
<!--            <form action="#">-->
<!--               <div class="search__popup-input">-->
<!--                  <input type="text" placeholder="Enter Your Keyword...">-->
<!--                  <button type="submit"><i class="far fa-search"></i> </button>-->
<!--               </div>-->
<!--            </form>-->
<!--         </div>-->
<!--      </div>-->
      <!-- search popup area end -->

      <!-- Mobile Menu Start -->
  <div class="fix">
    <div class="side-mobile-menu" :class="[menuOption.menuShow === true ? 'active' : '']">
     <div class="sidebar__wrapper">
          <div @click="menuOption.menuShow = !menuOption.menuShow" class="sidebar__close">
             <button class="sidebar__close-btn" id="sidebar__close-btn">
                <i class="fal fa-times"></i>
             </button>
          </div>
          <div class="sidebar__content">
             <div class="logo mb-40">
                <a href="/">
                <img src="../assets/img/logo/logo-dark.png" alt="logo" style="width: 90%" class="m-auto">
                </a>
             </div>
             <div class="mobile-menu fix">
                <div class="side-info-content sidebar-menu mm-menu">

                   <ul>
                     <li><router-link to="/">Home</router-link></li>

                     <li><router-link to="/the-company">{{ $t('header.company') }}</router-link></li>

                     <li class="menu-item-has-children has-droupdown" :class="[menuOption.useCasesDropDown === true ? 'active' : '']">
                         <a @click="menuOption.useCasesDropDown = !menuOption.useCasesDropDown">{{ $t('header.use_cases') }}</a>
                         <ul class="sub-menu" :class="[menuOption.useCasesDropDown === true ? 'active' : '',]">
                           <li><router-link to="/use-cases/case-network-balancing">{{ $t('header.network_balancing') }}</router-link></li>
                           <li><router-link to="/use-cases/case-FER-power">{{ $t('header.fer') }}</router-link></li>
                           <li><router-link to="/use-cases/case-reactive-power">{{ $t('header.reactive_power') }}</router-link></li>
                           <li><router-link to="/use-cases/case-islands">{{ $t('header.islands') }}</router-link></li>
                           <li><router-link to="/use-cases/case-industrial-system">{{ $t('header.industrial_systems') }}</router-link></li>
                           <li><router-link to="/use-cases/case-railway-network">{{ $t('header.railway_networks') }}</router-link></li>
                           <li><router-link to="/use-cases/case-space-system">{{ $t('header.space_systems') }}</router-link></li>
                         </ul>
                     </li>

                     <li><router-link to="/the-patent">{{ $t('header.patent') }}</router-link></li>

                     <li><router-link to="/contact-us">{{ $t('header.contact') }}</router-link></li>

                     <li class="menu-item-has-children has-droupdown" :class="[menuOption.langsDropDown === true ? 'active' : '']">
                       <a @click="menuOption.langsDropDown = !menuOption.langsDropDown">
                         <img v-bind:src="'/img/flags/' + $i18n.locale.toUpperCase() + '.png'" style="padding-right: 4px; height: 24px !important; width:auto !important;">
                         {{ $i18n.locale.toUpperCase() }}
                       </a>
                       <ul class="sub-menu" :class="[menuOption.langsDropDown === true ? 'active' : '']">
                         <li v-for="lang in langs" v-bind:key="lang"
                             @click="changeLocale(lang)"
                             :set="langImg = '/img/flags/' + lang.toUpperCase() + '.png'">
                           <a href="#"><img v-bind:src="langImg" style="padding-right: 4px; height: 24px !important; width:auto !important;" alt=""> {{ lang.toUpperCase() }}</a>
                         </li>
                       </ul>
                     </li>
                  </ul>
                </div>
             </div>

<!--               <div class="sidebar__search p-relative mt-40 mb-20 ">-->
<!--                  <form action="#">-->
<!--                     <input type="text" placeholder="Search...">-->
<!--                     <button type="submit"><i class="fad fa-search"></i></button>-->
<!--                  </form>-->
<!--               </div>-->
<!--               <div class="sidebar__contact mb-45">-->
<!--                  <ul>-->
<!--                        <li class="wow fadeInUp" data-wow-delay="1s">-->
<!--                           <div class="icon theme-color ">-->
<!--                              <i class="fal fa-envelope"></i>-->
<!--                           </div>-->
<!--                           <div class="text theme-color ">-->
<!--                              <span><a :href="'mailto:' + email">{{ email }}</a></span>-->
<!--                           </div>-->
<!--                        </li>-->
<!--                        <li v-if="phone" class="wow fadeInUp" data-wow-delay="1s">-->
<!--                           <div class="icon theme-color">-->
<!--                              <i class="fas fa-phone-volume"></i>-->
<!--                           </div>-->
<!--                           <div class="text theme-color">-->
<!--                              <span><a :href="'tel:' + phone">{{ phone }}</a></span>-->
<!--                           </div>-->
<!--                        </li>-->
<!--                        <li class="wow fadeInUp" data-wow-delay="1s">-->
<!--                           <div class="icon">-->
<!--                              <i class="fal fa-map-marker-alt"></i>-->
<!--                           </div>-->
<!--                           <div class="text">-->
<!--                              <a target="_blank" href="https://www.google.com/maps/place/Dhaka/@23.7806207,90.3492859,12z/data=!3m1!4b1!4m5!3m4!1s0x3755b8b087026b81:0x8fa563bbdd5904c2!8m2!3d23.8104753!4d90.4119873">{{ address1 }} <br> {{ address2 }}</a>-->
<!--                           </div>-->
<!--                        </li>-->
<!--                  </ul>-->
<!--               </div>-->
          </div>
       </div>
      </div>
  </div>

  <div class="body-overlay transition-3" v-bind:class="[menuOption.menuShow === true ? 'opened' : '']"></div>
    <!-- Mobile Menu End -->

</template>

<script>
export default {
    name:'HeaderHome',
     data () {
       return {
          isSticky:false,
          searchPopup:false,
          langs: JSON.parse(process.env.VUE_APP_LIST_SUPPORTED_LANGUAGES),
          menuOption: {
            useCasesDropDown: false,
            menuShow: false,
            langsDropDown: false,
          },
          company_name: process.env.VUE_APP_COMPANY_NAME,
          phone: process.env.VUE_APP_PHONE,
          email: process.env.VUE_APP_CONTACT_US_MAIL,
          address1: process.env.VUE_APP_COMPANY_ADDRESS_LINE_1,
          address2: process.env.VUE_APP_COMPANY_ADDRESS_LINE_2,
       }
    },
    methods : {
       handleSticky () {
          if(window.scrollY > 80){
             this.isSticky = true;
          }
          else{
             this.isSticky = false;
          }
       },
        handleSearch(){
          this.searchPopup = true;
       },
       handleClose(){
            this.searchPopup = false;
       },
       changeLocale(lang) {
           this.$i18n.locale = lang;
           this.$store.commit('setLang', lang);
       },
    },
    mounted() {
        window.addEventListener('scroll',this.handleSticky);

        let cookieBotScript = document.createElement('script');
        cookieBotScript.setAttribute('src', "https://consent.cookiebot.com/uc.js");
        cookieBotScript.id = "Cookiebot";
        cookieBotScript.type = "text/javascript";
        cookieBotScript.setAttribute("data-cbid", "c7ae5567-f58d-4ac2-878e-2c37056e3912");
        cookieBotScript.setAttribute("data-blockingmode", "auto");
        document.head.appendChild(cookieBotScript);
     },
}
</script>