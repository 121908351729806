import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      webPreferences: {
        lang: "en",
      },
    };
  },
  mutations: {
    setLang(state, lang) {
      state.webPreferences.lang = lang;
    },
  },
  getters: {
    getLang: (state) => { return state.webPreferences.lang; },
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, {
            expires: 365,
            secure: true,
            sameSite: "strict",
          }),
        removeItem: (key) => Cookies.remove(key),
      },
      paths: ["webPreferences"],
    }),
  ],
});

export default store;
