<template>
       <section class="project-details-area pt-120 pb-70">
            <div class="container">

<!--               <div class="row d-flex">-->
<!--                  <div class="col-xl-6 col-md-8 col-12 m-auto" style="text-align: justify">-->
<!--                    <h2>{{ companyName }} {{companyType}}</h2>-->
<!--                    <p class="mb-0">{{ $t('company.data') }}</p>-->
<!--                    <p class="mb-0">{{ $t('company.work_desc') }} <b>{{ $t('company.sector') }}</b>.</p>-->
<!--                  </div>-->

<!--                 <div class="col-xl-4 col-md-4 col-8 mx-auto mt-50 mt-md-auto">-->
<!--                     <img src="../../assets/img/company/bratislava.jpg" alt="" style="width:100%" class="image-radius-12">-->
<!--                 </div>-->
<!--              </div>-->



              <div class="row mt-120 d-flex">


                 <div class="col-xl-8" style="text-align: justify">
                   <p v-html="$t('company.sector_presentation1')"></p>
                   <p v-html="$t('company.sector_presentation2')"></p>
                   <p>{{ $t('company.sector_presentation3') }}</p>
                   <p><b>{{ $t('company.sector_presentation4', {companyName: companyName}) }}</b></p>
                 </div>

                <div class="col-xl-4 d-none d-xl-block m-auto">
                  <img src="../../assets/img/company/company_mission.jpg" alt="" style="width: 100%" class="image-radius-12">
                </div>
<!--                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">-->
<!--                        <div class="sidebar-wrap mb-40">-->
<!--                           <div class="sidebar-right">-->
<!--                              <div class="sidebar-single">-->
<!--                                    <label>Clients:</label>-->
<!--                                    <span>Jessica Brown</span>-->
<!--                              </div>-->
<!--                              <div class="sidebar-single">-->
<!--                                    <label>Category:</label>-->
<!--                                    <span>Design, Development</span>-->
<!--                              </div>-->
<!--                              <div class="sidebar-single">-->
<!--                                    <label>Value:</label>-->
<!--                                    <span>Jessica Brown</span>-->
<!--                              </div>-->
<!--                              <div class="sidebar-single">-->
<!--                                    <label>Date:</label>-->
<!--                                    <span>20 June, 2022</span>-->
<!--                              </div>-->
<!--                           </div>-->
<!--                        </div>-->
<!--                  </div>-->
               </div>
            </div>
         </section>
</template>

<script>
export default {
    name:'CompanyPresentation',
    data () {
        return {
            productName: process.env.VUE_APP_NAME,
            companyName: process.env.VUE_APP_COMPANY_NAME,
            companyType: process.env.VUE_APP_COMPANY_TYPE,
        }
    }
}
</script>