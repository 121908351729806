<template>
    <Header/>
<!--    <PageTitles title="Contact Us" subtitle="" />-->
    <Slider :slider-data="sliderData" slider-no-filter/>
    <ContactArea/>
    <Footer/>
</template>

<script>
import Header from 'src/components/Header.vue';
// import PageTitles from 'src/components/common/PageTitles.vue';
import Slider from "src/components/common/Slider";
import ContactArea from 'src/components/ContactUs/ContactArea.vue';
import Footer from 'src/components/Footer.vue';

export default {
    name:'ContactUs',
    components:{
        Header,
        // PageTitles,
        Slider,
        ContactArea,
        Footer,
    },
    data () {
        return {
            sliderData: [{id:'1', img:'contact.jpg', title: "common.contact_us", subtitle: "common.welcome_to"},
                         {id:'2', img:'contact2.jpg', title: "common.contact_us", subtitle: "common.welcome_to"}]
        }
    }
}
</script>