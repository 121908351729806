<template>
       <div class="about-area" id="basic_info">
            <div class="container pt-120 pb-90">
               <div class="row">
                  <div class="col-lg-6 col-md-7">
                     <div class="ab-box pl-50 mb-30">
                        <div>
<!--                           <h5>Our product</h5>-->
                           <h2 class="section-title">{{ $t('home.about_title') }}</h2>
                           <p style="text-align: justify" v-html="$t('home.about_desc1')"></p>
                           <p style="text-align: justify" v-html="$t('home.about_desc2')"></p>
                        </div>
<!--                        <div class="abs-item-box mt-40 mb-30">-->
<!--                           <div class="row">-->
<!--                              <div class="col-6">-->
<!--                                 <div class="abs-items mb-20">-->
<!--                                    <div class="abs-icon mb-15">-->
<!--                                       <i class="icon_ribbon_alt"></i>-->
<!--                                    </div>-->
<!--                                    <div class="abs-item-text fix">-->
<!--                                       <h3 class="abs-item-title">Acquisitions Finance Consulting</h3>-->
<!--                                    </div>-->
<!--                                 </div>-->
<!--                              </div>-->
<!--                           </div>-->
<!--                        </div>-->
                        <div class="ab-btn">
                           <a class="tp-btn" v-on:click="showDetails">{{ $t('home.technical_details') }}</a>
                           <div class="d-none" ref="tse-table-details">
                             <div class="table_about_details">
                               <div class="row">
                                 <div class="col-12 my-3">
                                   <h4>{{ $t('home.details_table_caption') }}</h4>
                                 </div>
                               </div>
                               <div class="row row_header">
                                 <div class="col-3 col_cell">{{ $t('home.details_table.header1') }}</div>
                                 <div class="col-3 col_cell">{{ $t('home.details_table.header2') }}</div>
                                 <div class="col-3 col_cell">{{ $t('home.details_table.header3') }}</div>
                                 <div class="col-3 col_cell">{{ $t('home.details_table.header4') }}</div>
                               </div>
                               <div class="row row_cells"
                                    v-for="rowIndex in table_details_rows" v-bind:key="rowIndex">
                                 <div class="col-3 col_cell"><b>{{ $t('home.details_table.row' + rowIndex + '.cell1') }}</b></div>
                                 <div class="col-3 col_cell">{{ $t('home.details_table.row' + rowIndex + '.cell2') }}</div>
                                 <div class="col-3 col_cell">{{ $t('home.details_table.row' + rowIndex + '.cell3') }}</div>
                                 <div class="col-3 col_cell">{{ $t('home.details_table.row' + rowIndex + '.cell4') }}</div>
                               </div>

                             </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6 col-md-5 m-auto">
                     <div class="abs-images abs-images-2 pl-50">
                        <div class="row">
                           <div class="col-7">
                              <div class="abs-img img-filter mb-30">
                                 <img src="../../assets/img/about/battery.png" alt="">
                              </div>
                           </div>
                           <div class="col-5">
                              <div class="abs-img mb-30">
                                 <img src="../../assets/img/about/img1.jpg" alt="">
                              </div>
<!--                              <div class="ab-line-shape w-100 mb-20"></div>-->
<!--                              <div class="ab-line-shape w-50"></div>-->
<!--                             <a class="tp-btn" href="/files/pitch_deck.pdf" target="_blank">-->
<!--                               <i class="fas fa-download"></i> Pitch Deck-->
<!--                             </a>-->

                           </div>
                        </div>
                       <div class="row">
                         <button v-b-modal.modal-center class="tp-btn" href="https://www.youtube.com/embed/jMpjF7WCTic">
                           <i class="fas fa-play"></i> Intro video
                         </button>

                       </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>

  <!-- video modal  start-->
  <div>
    <div class="video-modal-wrapper">
      <b-modal id="modal-center" centered>
        <p><iframe src="https://www.youtube.com/embed/jMpjF7WCTic" title="Intro video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
      </b-modal>
    </div>
  </div>
  <!-- video modal  end-->
</template>

<script>
import Swal from "sweetalert2";

export default {
  name:'BasicInfo',
    data () {
      return {
          table_details_rows: 8
      }
    },
    methods: {
      showDetails() {
        Swal.fire({
            width: '80%',
            title: this.$i18n.t('home.details_table_title'),
            html: this.$refs["tse-table-details"].innerHTML,
            confirmButtonColor: '#2ecb70',
            showCloseButton: true,
            confirmButtonText: this.$i18n.t("common.close")
        });
      }
   }
}
</script>