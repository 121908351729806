<template>
  <Header/>
  <Slider :slider-data="sliderData" slider-no-filter/>

  <section class="pt-50 pb-50">
    <div class="container">
       <div class="row">
          <div class="col-xl-8 col-lg-10 m-auto">
            <div ref="cookieScript"></div>
            <router-link to="/" class="tp-btn mt-25">Back To Home</router-link>
          </div>
       </div>
    </div>
  </section>

  <Footer/>
</template>

<script>
import Header from 'src/components/Header.vue';
import Slider from 'src/components/common/Slider.vue';
import Footer from 'src/components/Footer.vue';

export default {
    name: 'CookiePolicy',
    components: {
        Header,
        Slider,
        Footer,
    },
    data () {
        return {
            sliderData: [{id:'1', img:'cookies.jpg', title: 'Cookie Policy', subtitle: "common.welcome_to"},
                         {id:'2', img:'cookies2.jpg', title: 'Cookie Policy', subtitle: "common.welcome_to"}]
        }
    },
    mounted() {
        let cookieBotScript = document.createElement('script');
        cookieBotScript.setAttribute('src', "https://consent.cookiebot.com/c7ae5567-f58d-4ac2-878e-2c37056e3912/cd.js");
        cookieBotScript.id = "CookieDeclaration";
        cookieBotScript.async = true;
        cookieBotScript.type = "text/javascript";
        this.$refs.cookieScript.insertAdjacentElement('beforeend', cookieBotScript);
    }
}
</script>