<template>
    <Header/>
    <Slider :slider-data="sliderData" slider-no-filter/>
    <TheCompany/>
    <CompanyPresentation/>
    <TeamNoImages/>
<!--    <Cta/>-->
<!--    <Brand/>-->
    <Footer/>
</template>

<script>
import Header from 'src/components/Header.vue';
import Slider from 'src/components/common/Slider.vue';
import TheCompany from 'src/components/TheCompany/TheCompany.vue';
import CompanyPresentation from "src/components/TheCompany/CompanyPresentation.vue";
import TeamNoImages from 'src/components/TheCompany/TeamNoImages.vue';
// import Cta from 'src/components/Home/Cta.vue';
// import Brand from 'src/components/common/BrandArea.vue';
import Footer from 'src/components/Footer.vue';

export default {
    name:'CompanyProfile',
    components:{
        Header,
        Slider,
        TheCompany,
        CompanyPresentation,
        TeamNoImages,
        // Cta,
        // Brand,
        Footer,
    },
    data () {
        return {
            sliderData: [
                {id:'1', img:'company.jpg', title: "header.company", subtitle: "common.welcome_to"},
                {id:'2', img:'company2.jpg', title: "header.company", subtitle: "common.welcome_to"}]
        }
    }
}
</script>