import { createWebHistory,createRouter } from "vue-router";
import HomePage from "src/pages/HomePage";
import CompanyProfile from "src/pages/CompanyProfile";
import ThePatent from "src/pages/ThePatent";
import UseCases from "src/pages/UseCases";
import ContactUs from "src/pages/ContactUs";
import NotFound404 from "src/pages/404";
import CookiePolicy from "src/pages/CookiePolicy";
import PrivacyPolicy from "src/pages/PrivacyPolicy";

const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path:'/',
            component:HomePage,
        },
        {
            path:'/the-company',
            component:CompanyProfile,
        },
        {
            path:'/the-patent',
            component:ThePatent,
        },
        {
            path:'/use-cases/:caseId?',
            component:UseCases,
        },
        {
            path:'/contact-us',
            component:ContactUs,
        },
        {
            path:'/cookie-policy',
            component: CookiePolicy,
        },
        {
            path:'/privacy-policy',
            component: PrivacyPolicy,
        },
        { path: '/:pathMatch(.*)*', name: "NotFound", component: NotFound404 },
    ]
});

router.beforeEach((to, from, next) => {
    next();
    window.scrollTo(0, 0)
});

export default router