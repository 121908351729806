<template>
  <div class="pt-120" id="case-space-system">
    <div class="container">
       <div class="row">
         <div class="col-lg-5 col-md-4"></div>
         <div class="col-lg-7 col-md-8">
            <h2 class="section-title">{{ $t('use_cases.space.title') }}</h2>
         </div>

         <div class="col-lg-5 col-md-4">
           <div class="abs-images abs-images-2 pl-50">
             <div class="row">
               <div class="col-7">
                 <div class="abs-img mb-30">
                   <img src="../../assets/img/case/space-1.jpg" alt="">
                 </div>
               </div>
               <div class="col-5" style="margin-top: 35%; margin-left: -25%; z-index: 1">
                 <div class="abs-img mb-30 sphere-box">
                   <img src="../../assets/img/case/space-2.jpg" alt="" style="border-radius: 50%; width: 120%">
                 </div>
               </div>
             </div>
           </div>
         </div>

         <div class="col-lg-7 col-md-8">
           <div class="ab-box mb-30">

             <p style="text-align: justify" v-html="$t('use_cases.space.desc1')"></p>
             <p style="text-align: justify" v-html="$t('use_cases.space.desc2')"></p>
             <p style="text-align: justify" v-html="$t('use_cases.space.desc3')"></p>

           </div>
         </div>
       </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'SpaceSystem',
}
</script>