<template>
     <div class="main-services bg-light pt-120 pb-90" :style="{backgroundImage:'url(' + require('../../assets/img/pattern/pt1.png') +')'}">
            <div class="container">
               <div class="row mb-60">
                  <div class="col-12">
                     <div class="sec-wrapper text-center">
<!--                        <h5>Main features</h5>-->
                        <h2 class="section-title">{{ $t('home.features.title') }}</h2>
                     </div>
                  </div>
               </div>
               <div class="row text-center d-flex">
                  <div v-for="(service, index) in servicesData" :key="service.id" class="col-xl-4 col-lg-5 col-md-6 col-12 mx-auto mb-30">
                     <div class="mfbox-container-green">
                        <div class="mfbox mfbox-white">
                           <div class="mf-shape"></div>
                           <div class="mfbox__icon mb-15">
                              <img :src="service.icon" alt=""/>
                           </div>
                           <div class="mfbox__text">
                              <h3 class="mf-title">{{ $t('home.features.' + service.id + '.title') }}</h3>
                              <p>{{ $t('home.features.' + service.id + '.small_desc') }}</p>
                           </div>
                           <div class="mf-btn">
                              <a class="squire-btn" @click="openAdvantage(index)"><i class="fal fa-angle-right"></i></a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
</template>

<style>
  .swal2-close:focus {
    box-shadow: none !important;
  }
</style>

<script>
import Swal from "sweetalert2";
import imagePowerHV from "src/assets/img/advantages/power-hv.jpg";
import imageBatteryEfficiency from "src/assets/img/advantages/battery-efficiency.jpg";
import imageSmartGrid from "src/assets/img/advantages/smart-grid.jpg";
import imageBattery from "src/assets/img/advantages/battery.jpg";
import imageCostAnalysis from "src/assets/img/advantages/cost-analysis.jpg";
import imageBatteryRecycling from "src/assets/img/advantages/batteryRecycling.jpg";
import iconRenewableEnergy from "src/assets/img/flaticon/001-renewable-energy.png";
import iconCarBattery from "src/assets/img/flaticon/004-car-battery.png";
import iconSmartGrid from "src/assets/img/flaticon/003-smart-grid.png";
import iconTime from "src/assets/img/flaticon/005-time.png";
import iconPieChart from "src/assets/img/flaticon/006-pie-chart.png";
import iconSavePlanet from "src/assets/img/flaticon/007-save-the-planet.png";

export default {
   name:'ProductAdvantages',
   data () {
      return {
         servicesData:[
            {id:'1', icon: iconRenewableEnergy, img: imagePowerHV},
            {id:'2', icon: iconCarBattery, img: imageBatteryEfficiency},
            {id:'3', icon: iconSmartGrid, img: imageSmartGrid},
            {id:'4', icon: iconTime, img: imageBattery},
            {id:'5', icon: iconPieChart, img: imageCostAnalysis},
            {id:'6', icon: iconSavePlanet, img: imageBatteryRecycling},
         ]
      }
   },
   methods: {
      openAdvantage(index) {
         Swal.fire({
            width: '48em',
            title: this.$i18n.t('home.features.' + this.servicesData[index].id + '.title'),
            html: '<div class="container"><p style="text-align: justify; text-align-last: center;">' + this.$i18n.t('home.features.' + this.servicesData[index].id + '.desc') + '</p></div>',
            imageUrl: this.servicesData[index].img,
            imageWidth: '90%',
            imageHeight: 'auto',
            imageAlt: '',
            showConfirmButton: false,
            // confirmButtonColor: '#2ecb70',
            // confirmButtonText: this.$i18n.t("common.close"),
            showCloseButton: true,
            customClass: {image: 'image-radius-12', container: 'no-overscroll-behavior'}
         })
      }
   }
}
</script>