<template>
  <section class="project-details-area pt-120 pb-70">
    <div class="container">

       <div class="row soft-bg-container">
         <div class="soft-bg" :style="{ backgroundImage:'url(' + require('../../assets/img/patent/patented.jpg') + ')'}"></div>
          <div class="col-lg-8 col-md-12">
              <div class="p-details-content mb-40 mt-20">
                <h3 style="color: black">{{ $t('patent.title') }}</h3>
                <p class="mb-1" style="color: black; text-align: justify">{{ $t('patent.desc1') }}</p>
                <ul class="mb-2">
                  <li class="bullet-outside">{{ $t('patent.li1') }}</li>
                  <li class="bullet-outside">{{ $t('patent.li2') }}</li>
                  <li class="bullet-outside">{{ $t('patent.li3') }}</li>
                  <li class="bullet-outside">{{ $t('patent.li4') }}</li>
                </ul>
                <p style="color: black; text-align: justify">{{ $t('patent.desc2', { companyName: companyName, companyType: companyType }) }}</p>
              </div>
          </div>

         <div class="col-lg-4 col-md-6 m-auto">
           <div class="project-big-thumb mt-20">
             <img src="../../assets/img/patent/patented.jpg" alt="" style="-webkit-filter: none; filter: none">
           </div>
         </div>
<!--                  <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">-->
<!--                        <div class="sidebar-wrap mb-40">-->
<!--                           <div class="sidebar-right">-->
<!--                              <div class="sidebar-single">-->
<!--                                    <label>Clients:</label>-->
<!--                                    <span>Jessica Brown</span>-->
<!--                              </div>-->
<!--                              <div class="sidebar-single">-->
<!--                                    <label>Category:</label>-->
<!--                                    <span>Design, Development</span>-->
<!--                              </div>-->
<!--                              <div class="sidebar-single">-->
<!--                                    <label>Value:</label>-->
<!--                                    <span>Jessica Brown</span>-->
<!--                              </div>-->
<!--                              <div class="sidebar-single">-->
<!--                                    <label>Date:</label>-->
<!--                                    <span>20 June, 2022</span>-->
<!--                              </div>-->
<!--                           </div>-->
<!--                        </div>-->
<!--                  </div>-->
       </div>
    </div>
  </section>
</template>

<script>
export default {
    name:'PatentHistory',
    data () {
        return {
            productName: process.env.VUE_APP_NAME,
            companyName: process.env.VUE_APP_COMPANY_NAME,
            companyType: process.env.VUE_APP_COMPANY_TYPE,
        }
    }
}
</script>