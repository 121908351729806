<template>
   <div class="pt-120" id="case-industrial-system">
      <div class="container">
         <div class="row">
            <div class="col-12">
               <h2 class="section-title text-center">{{ $t('use_cases.industrial.title') }}</h2>
            </div>
            <div class="col-lg-4 col-md-6 mx-auto">
               <div class="sphere-box">
                  <img src="../../assets/img/case/industrial-1.jpg" alt="">

                  <p v-html="$t('use_cases.industrial.desc1')"></p>
               </div>
            </div>

            <div class="col-lg-4 col-md-6 mx-auto">
               <div class="sphere-box">
                  <img src="../../assets/img/case/industrial-2.jpg" alt="">

                  <p v-html="$t('use_cases.industrial.desc2')"></p>
               </div>
            </div>

            <div class="col-lg-4 col-md-6 mx-auto">
               <div class="sphere-box">
                  <img src="../../assets/img/case/industrial-3.jpg" alt="">

                  <p v-html="$t('use_cases.industrial.desc3')"></p>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
    name:'IndustrialSystem',
}
</script>