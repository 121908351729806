<template>
    <Header/>
    <Slider/>
    <BasicInfo/>
    <Advantages/>
    <Footer/>
</template>

<script>
import Header from 'src/components/Header.vue';
import Slider from 'src/components/Landing/Slider.vue';
import BasicInfo from 'src/components/Landing/BasicInfo.vue';
import Advantages from 'src/components/Landing/Advantages.vue';
import Footer from 'src/components/Footer.vue';

export default {
    name:'HomePage',
    components:{
        Header,
        Slider,
        BasicInfo,
        Advantages,
        Footer,
    }
}
</script>