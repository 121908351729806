<template>
   <div class="pt-120" id="case-network-balancing">
      <div class="container">
         <div class="row">
            <div class="col-12 text-center">
               <h2 class="section-title">{{ $t('use_cases.net_balance.title') }}</h2>
               <p>{{ $t('use_cases.net_balance.sub_title') }}</p>
            </div>
         </div>
         <div class="row justify-content-center py-3">
            <div class="col-xl-4 col-lg-5 col-md-5 col-6">
               <div class="ab-img">
                  <img src="../../assets/img/case/net-balance-1.jpg" alt="">
               </div>
            </div>
            <div class="col-xl-8 col-lg-7 col-md-7 vertical-align">
               <p v-html="$t('use_cases.net_balance.desc1')"></p>
            </div>
         </div>

         <div class="row justify-content-center py-3">

            <div class="col-xl-8 col-lg-7 col-md-7 order-1 order-md-0 vertical-align">
               <p>{{ $t('use_cases.net_balance.desc2') }}</p>
            </div>

            <div class="col-xl-4 col-lg-5 col-md-5 col-6">
               <div class="ab-img">
                  <img src="../../assets/img/case/net-balance-2.jpg" alt="">
               </div>
            </div>

         </div>


         <div class="row justify-content-center py-3">

            <div class="col-xl-4 col-lg-5 col-md-5 col-6 text-center">
               <div class="ab-img">
                  <img src="../../assets/img/case/net-balance-3.jpg" alt="" style="width: 60%">
               </div>
            </div>
            <div class="col-xl-8 col-lg-7 col-md-7 vertical-align">
               <p v-html="$t('use_cases.net_balance.desc3')"></p>
            </div>

         </div>

         <div class="row justify-content-center py-3">

            <div class="col-xl-8 col-lg-7 col-md-7 order-1 order-md-0 vertical-align">
               <p v-html="$t('use_cases.net_balance.desc4')"></p>
            </div>

            <div class="col-xl-4 col-lg-5 col-md-5 col-6 text-center">
               <div class="ab-img">
                  <img src="../../assets/img/case/net-balance-4.jpg" alt="" style="padding-left: 20%">
               </div>
            </div>
         </div>

      </div>
   </div>
</template>

<script>
export default {
    name:'NetworkBalancing',
}
</script>