<template>
   <div class="pt-120" id="case-FER-power">
      <div class="container">
         <div class="row">
            <div class="col-12 text-center">
               <h2 class="section-title">{{ $t('use_cases.fer_power.title') }}</h2>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-6 col-md-6 m-auto">
               <div class="ab-img">
                  <img src="../../assets/img/case/fer.jpg" alt="" class="image-radius-12">
               </div>
            </div>
            <div class="col-lg-6 m-auto">
               <p style="text-align: justify" v-html="$t('use_cases.fer_power.desc1')"></p>

               <p style="text-align: justify" v-html="$t('use_cases.fer_power.desc2')"></p>
            </div>
         </div>


      </div>
   </div>
</template>

<script>
export default {
    name:'FERPower',
}
</script>