<template>
     <div class="team-area pt-120 pb-90">
            <div class="container">
               <div class="row mb-60">
                  <div class="col-12">
                     <div class="sec-wrapper text-center">
<!--                        <h5>Our Team</h5>-->
                        <h2 class="section-title">Our Team</h2>
                     </div>
                  </div>
               </div>
               <div class="row switch_col" v-for="team in membersData" :key="team.id">
                  <div class="col-xl-4 col-lg-4 col-md-6 text-center teamnoimg_col-name-role">
                    <h2 class="teamnoimg_name"> {{team.name}}</h2>
                    <h3 class="teamnoimg_role" v-if="team.role">{{team.role}}</h3>
                    <div class="teamnoimg_role" v-if="team.img"><br><br><img :src="getImgUrl(team.img)" style="width: 100%; height: auto;"></div>
                  </div>
                  <div class="col-xl-8 col-lg-8 col-md-6 teamnoimg_col-description p-5">
                    <ul class='team-list'>
                      <li v-for="desc in team.description" v-bind:key="desc">{{ $t(desc) }}</li>
                    </ul>
                  </div>
               </div>
            </div>
         </div>
</template>

<script>
export default {
   name:'TeamNoImages',
   data () {
       return {
           membersData: [
             {
                 id: "1",
                 name: "Angelo Stucchi",
                 role: "Inventor",
                 description: ['team.stucchi.1', 'team.stucchi.2', 'team.stucchi.3'],
                 socials: [{icon: "", link: ""}]
             },
             {
               id: "2",
               name: "Sandro Benin",
               role: "Chief Engineer",
               description: ['team.benin.1', 'team.benin.2', 'team.benin.3'],
               socials: [{icon: "", link: ""}]
             },
             {
               id: "3",
               name: "Emanuele Galbusera",
               role: "CFO",
               description: ['team.galbusera.1', 'team.galbusera.2', 'team.galbusera.3'],
               socials: [{icon: "", link: ""}]
             },
             {
                 id: "4",
                 name: "Gianluca Franco",
                 role: "CBDO",
                 description: ['team.franco.1', 'team.franco.2', 'team.franco.3'],
                 socials: [{icon: "", link: ""}]
             },
             {
                 id: "5",
                 name: "Mario Ferrari",
                 role: "CEO",
                 description: ['team.ferrari.1', 'team.ferrari.2', 'team.ferrari.3', 'team.ferrari.4'],
                 socials: [{icon: "", link: ""}]
             },
             {
                 id: "6",
                 name: "Andrea Pezzato",
                 role: "COO",
                 description: ['team.pezzato.1', 'team.pezzato.2', 'team.pezzato.3', 'team.pezzato.4', 'team.pezzato.5'],
                 socials: [{icon: "", link: ""}]
             },
             // {
             //     id: "7",
             //     name: "GENIUS ENERGY Srl",
             //     role: "",
             //     description: ['team.genius_energy.1', 'team.genius_energy.2', 'team.genius_energy.3', 'team.genius_energy.4', 'team.genius_energy.5'],
             //     socials: [{icon: "", link: ""}],
             //     img: "genius_energy.png"
             // },
          ],
       }
   },
    methods: {
        getImgUrl(pic) {
          return require("../../assets/img/team/" + pic);
        },
    },
}
</script>


<style>
  .team-list li {
    list-style-type: disclosure-closed;
    list-style-position: outside;
  }
</style>