<template>
  <div class="slider-area fix p-relative">
    <div class="slider-active swiper-container">
      <swiper
        ref="mySwiper"
        :modules="modules"
        :effect="'fade'"
        :loop="true"
        :navigation="{nextEl:'.swiper-button-next',prevEl:'.swiper-button-prev'}"
        :autoplay="{delay: 5000}">
        <swiper-slide v-for="item in sliderData" :key="item.id">
          <div class="slider-list slider-no-filter slider-height-2 swiper-slide d-flex align-items-center slider-pt">
            <div class="slider-shape"></div>
            <div class="slider-shape-line"></div>
            <div class="slider-shape-line2"></div>
            <div class="slider-bg img-zoom" :style="{ backgroundImage:'url(' + require('../../assets/img/slider/' + item.img) + ')', }"></div>
            <div class="container p-relative">
              <div class="row">
                <div class="col-12 position-relative">
                  <div class="slider-circle-shape slider-circle-shape-2"></div>
                  <div class="slider-text slider-text-2 z-index">
                    <span class="slider-sub-title">
                      <span v-html="$t(item.subtitle)"></span> {{ productName.toUpperCase() }} - by {{ companyName }}
                    </span>
                    <h2 class="slider-title" v-html="$t(item.title)"></h2>
                    <div class="slide-btn mt-30 d-flex align-items-center">
                      <a class="tp-btn" v-on:click="scrollToAbout()">{{ $t('slider.discover_more') }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      
      <!-- If we need pagination -->
      <!-- <div class="swiper-pagination"></div> -->

      <!-- If we need navigation buttons -->
      <div class="swiper-button-prev sp-arrow">
        <i class="far fa-angle-left"></i>
      </div>
      <div class="swiper-button-next sp-arrow">
        <i class="far fa-angle-right"></i>
      </div>
    </div>
  </div>

</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, EffectFade,Autoplay } from "swiper";

export default {
  name: "SliderHome",
   components: {
    Swiper,
    SwiperSlide,
  },
  data () {
      return {
          sliderData:[
              {
                  id:'1',
                  img:'home1.jpg',
                  title: "slider.slogan1",
                  subtitle: "common.welcome_to"
              },
              {
                  id:'2',
                  img:'home2.jpg',
                  title: "slider.slogan2",
                  subtitle: "common.welcome_to"
              },
          ],
          productName: process.env.VUE_APP_NAME,
          companyName: process.env.VUE_APP_COMPANY_NAME,
      }
  },
  methods: {
      scrollToAbout() {
          let elem = document.getElementById("basic_info");
          if(elem)
          {
              // elem.scrollIntoView({behavior: 'smooth'});
              window.scrollTo(0, elem.offsetTop - 100);
          }
      }
  },
   setup() {
    return {
      modules: [Navigation, EffectFade, Autoplay],
    };
  },
};
</script>