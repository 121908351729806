<template>
  <Header/>
  <Slider :slider-data="sliderData"/>
    <!-- error area start -->
    <section class="error__area pt-50 pb-50">
        <div class="container">
           <div class="row">
              <div class="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                 <div class="error__item text-center">
                    <div class="error__thumb mb-45">
                       <img src="../assets/img/error/error.png" alt="">
                    </div>
                    <div class="error__content">
                       <h3 class="error__title">Page Not Found!</h3>
                       <p>Please try searching for some other page.</p>
                       <router-link to="/" class="tp-btn">Back To Home</router-link>
                    </div>
                 </div>
              </div>
           </div>
        </div>
    </section>
  <!-- error area end -->
  <Footer/>
</template>

<script>
import Header from 'src/components/Header.vue';
import Slider from 'src/components/common/Slider.vue';
import Footer from 'src/components/Footer.vue';

export default {
    name: 'NotFound404',
    components: {
        Header,
        Slider,
        Footer,
    },
    data () {
        return {
            sliderData: [{id:'1', img:'404.jpg', title: 'PAGE NOT FOUND', subtitle: "common.welcome_to"}]
        }
    }
}
</script>