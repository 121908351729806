<template>
    <Header/>
    <Slider :slider-data="sliderData" slider-no-filter/>
    <PatentHistory/>
    <Footer/>
</template>

<script>
import Header from 'src/components/Header.vue';
import Slider from 'src/components/common/Slider.vue';
import PatentHistory from "src/components/Patent/PatentHistory.vue";
import Footer from 'src/components/Footer.vue';

export default {
    name:'ThePatent',
    components:{
        Header,
        Slider,
        PatentHistory,
        Footer,
    },
    data () {
        return {
            sliderData: [{id:'1', img:'patented.jpg', title: "header.patent", subtitle:"common.welcome_to"},
                         {id:'2', img:'patented2.jpg', title: "header.patent", subtitle:"common.welcome_to"}]
        }
    }
}
</script>