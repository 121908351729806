<template>
    <Header/>
    <Slider :slider-data="sliderData" slider-no-filter/>
    <NetworkBalancing/>
    <FERPower/>
    <ReactivePower/>
    <IslandsUse/>
    <IndustrialSystem/>
    <RailwayNetwork/>
    <SpaceSystem/>

    <Footer/>
</template>

<script>
import Header from 'src/components/Header.vue';
import Slider from 'src/components/common/Slider.vue';
import NetworkBalancing from 'src/components/UseCases/NetworkBalancing.vue';
import FERPower from 'src/components/UseCases/FERPower.vue';
import ReactivePower from 'src/components/UseCases/ReactivePower.vue';
import IslandsUse from 'src/components/UseCases/Islands.vue';
import IndustrialSystem from 'src/components/UseCases/IndustrialSystem.vue';
import RailwayNetwork from 'src/components/UseCases/RailwayNetwork.vue';
import SpaceSystem from 'src/components/UseCases/SpaceSystem.vue';

// import Faq from 'src/components/Home-two/HomeTwoFaq.vue';
// import BrandArea from 'src/components/common/BrandArea.vue';
import Footer from 'src/components/Footer.vue';

export default {
    name:'UseCases',
    components: {
        Header,
        Slider,
        NetworkBalancing,
        FERPower,
        ReactivePower,
        IslandsUse,
        IndustrialSystem,
        RailwayNetwork,
        SpaceSystem,
        Footer,
    },
    data () {
        return {
            sliderData: [{id:'1', img:'use_cases.jpg', title: "header.use_cases", subtitle: "common.welcome_to"},
                         {id:'2', img:'use_cases2.jpg', title: "header.use_cases", subtitle: "common.welcome_to"}]
        }
    },
    methods: {
      scrollToCase(caseId, offset) {
          console.log("called");
          let elem = document.getElementById(caseId.toString());
          if(elem)
              window.scrollTo(0, elem.offsetTop - offset);
      }
    },
    mounted() {
        let caseId = this.$route.params.caseId;
        if (caseId)
            this.scrollToCase(caseId, 80);
    },
    beforeUpdate() {
        let caseId = this.$route.params.caseId;
        if (caseId) {
            if (window.pageYOffset === 0)
                this.scrollToCase(caseId, 80);
            else
                this.scrollToCase(caseId, 0);
        }
    }
}
</script>