<template>
     <section class="contact-area pt-120 pb-80 fix">
         <div class="container">
             <div class="row">
                 <div class="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 m-auto ">
                     <div class="section-title-wrapper mb-15">
<!--                         <h5 class="section-subtitle mb-20">contact with us</h5>-->
                         <h2 class="section-title">{{ $t('common.contact_us') }}</h2>
                     </div>
                     <div class="contact-info">
<!--                         <div v-if="phone" class="single-contact-info d-flex align-items-center">-->
<!--                             <div class="contact-info-icon">-->
<!--                                 <a href="#"><i class="fad fa-comments"></i></a>-->
<!--                             </div>-->
<!--                             <div class="contact-info-text mt-10">-->
<!--                                 <span>Call Anytime</span>-->
<!--                                 <h5><a :href="'tel:' + phone">{{ phone }}</a></h5>-->
<!--                             </div>-->
<!--                         </div>-->
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                               <a :href="'mailto:' + email" target="_blank"><i class="fal fa-envelope"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>{{ $t('contact.email_us') }}</span>
                                 <h5><a :href="'mailto:' + email" target="_blank">{{ email }}</a></h5>
                             </div>
                         </div>
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="https://goo.gl/maps/uz4daCCuqKJ3Ur6w7" target="_blank"><i class="fal fa-map-marker-alt"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>{{ $t('contact.visit') }}</span>
                               <h5><a href="https://goo.gl/maps/uz4daCCuqKJ3Ur6w7" target="_blank">{{ address }}<br> {{ address2 }}</a></h5>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div class="col-xl-5 col-lg-6 col-md-5 d-none d-md-block m-auto text-center">
                     <img src="../../assets/img/contact-us.jpg" alt="contact us" style="width: 90%" class="image-radius-12"/>
                 </div>
             </div>
         </div>
    </section>
</template>

<script>
export default {
    name:'ContactArea',
    data () {
        return {
            // phone: process.env.VUE_APP_PHONE,
            email: process.env.VUE_APP_CONTACT_US_MAIL,
            address: process.env.VUE_APP_COMPANY_ADDRESS_LINE_1,
            address2: process.env.VUE_APP_COMPANY_ADDRESS_LINE_2,
            address_coordinates: process.env.VUE_APP_COMPANY_ADDRESS_COORDINATES,
        }
    }
}
</script>