<template>
    <footer :style="{backgroundImage:'url('+ require('../assets/img/bg/footer-bg.jpg') + ')'}" class="footer-2 pt-95 position-relative">
      <div class="common-shape-wrapper">
         <div class="common-shape-inner"></div>
      </div>
      <div class="footer-area pb-60">
         <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-12 m-auto col-left-center-md">
                 <div class="widget mb-30">
                    <div class="footer-logo mb-25">
                      <router-link to="/"><img src="../assets/img/logo/logo.png" class="img-fluid" alt="footer-logo"></router-link>
                    </div>
                    <p class="mb-0">{{ $t('footer.slogan') }}</p>
                    <p class="mb-0">{{ $t('footer.registered_mark', { companyName: company_name, companyType: company_type}) }}</p>
<!--                    <p>{{ $t('footer.share_capital') }}</p>-->
<!--                              <div class="footer-social footer-social-2">-->
<!--                                  <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>-->
<!--                                  <a href="#" target="_blank"><i class="fab fa-facebook"></i></a>-->
<!--                                  <a href="#" target="_blank"><i class="fab fa-pinterest-p"></i></a>-->
<!--                                  <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>-->
<!--                              </div>-->
                 </div>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-6 m-auto col-left-center-xs">
                 <div class="widget mb-30">
                    <h4 class="widget-title mb-20">{{ $t('footer.quick_links') }}</h4>
                    <ul>
                      <li><router-link to="/the-company">{{ $t('header.company') }}</router-link></li>
                      <li><router-link to="/use-cases">{{ $t('header.use_cases') }}</router-link></li>
                      <li><router-link to="/the-patent">{{ $t('header.patent') }}</router-link></li>
                      <li><router-link to="/contact-us">{{ $t('header.contact') }}</router-link></li>
                    </ul>
                 </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-6 m-auto col-left-center-xs">
                 <div class="widget widget-contact mb-30">
                    <h4 class="widget-title mb-20">{{ $t('header.contact') }}</h4>
                    <ul>
                      <li class="pb-10"><a href="https://goo.gl/maps/uz4daCCuqKJ3Ur6w7" target="_blank">{{ address1 }} <br> {{ address2 }}</a></li>
                      <li><a :href="'mailto:' + email" target="_blank"><i class="fal fa-envelope-open"></i>{{ email }}</a></li>
                      <li v-if="phone"><i class="fal fa-phone-alt"></i><a :href="'tel:'+ phone">{{ phone }}</a></li>
                    </ul>
                 </div>
              </div>
<!--                        <div class="col-lg-3 col-sm-6">-->
<!--                           <div class="widget mb-30">-->
<!--                              <h4 class="widget-title mb-30">Newsletter</h4>-->
<!--                              <p class="mb-20">Subscribe to Our Newsletter for Daily News and Updates</p>-->
<!--                              <div class="widget-newsletter">-->
<!--                                    <form action="#">-->
<!--                                       <input type="email" placeholder="Email Address">-->
<!--                                       <button type="submit">Send</button>-->
<!--                                    </form>-->
<!--                              </div>-->
<!--                           </div>-->
<!--                        </div>-->

              <div class="col-12 text-center">
                <p> {{ $t('footer.icons_made_from') }} <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.
                  <a style="cursor: pointer; color: white" v-on:click="openListIcons">{{ $t('footer.click_here') }}</a> {{ $t('footer.icons_list') }}
                </p>
              </div>
            </div>
         </div>
      </div>
      <div class="copyright-area">
         <div class="container">
            <div class="copyright-bg">
              <div class="row align-items-center">
                 <div class="col-md-6">
                    <div class="copyright">
                      <span>© {{ company_name }} {{company_type}} 2023<span v-if="year !== 2023" class="d-inline"> - {{year}}</span>. {{ $t('common.right_reserved') }}</span>
                    </div>
                 </div>
                 <div class="col-md-6">
                    <div class="privacy-text text-md-end">
                      <ul>
                         <li>
                            <router-link to="/cookie-policy">Cookie Policy</router-link>
                            <router-link to="/privacy-policy">Privacy Policy</router-link>
                         </li>
                      </ul>
                    </div>
                 </div>
              </div>
            </div>
         </div>
      </div>
   </footer>
</template>

<script>
  import Swal from "sweetalert2";

export default {
    name:'FooterHome',
    data () {
        return {
            year: (new Date()).getFullYear(),
            company_name: process.env.VUE_APP_COMPANY_NAME,
            company_type: process.env.VUE_APP_COMPANY_TYPE,
            phone: process.env.VUE_APP_PHONE,
            email: process.env.VUE_APP_CONTACT_US_MAIL,
            address1: process.env.VUE_APP_COMPANY_ADDRESS_LINE_1,
            address2: process.env.VUE_APP_COMPANY_ADDRESS_LINE_2,
        }
    },
    methods: {
        openListIcons() {
            Swal.fire({
                width: '48em',
                title: "Flaticon",
                html: '<div>Icons made by <a href="https://www.flaticon.com/authors/phatplus" title="phatplus">phatplus</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.flaticon.com/authors/wanicon" title="wanicon">wanicon</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.flaticon.com/authors/uniconlabs" title="Uniconlabs">Uniconlabs</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.flaticon.com/authors/surang" title="surang">surang</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.flaticon.com/authors/fragneel" title="Fragneel">Fragneel</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>',
                showConfirmButton: false,
                showCloseButton: true,
            });
        }
    }
}
</script>