<template>
  <div class="pt-120" id="case-railway-network">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h2 class="section-title">{{ $t('use_cases.railway.title') }}</h2>
        </div>
      </div>

      <div class="row justify-content-center py-3">
        <div class="col-xl-4 col-lg-5 col-md-5 col-6">
          <div class="ab-img">
            <img src="../../assets/img/case/railway-1.jpg" alt="" class="image-radius-12">
          </div>
        </div>

        <div class="col-xl-8 col-lg-7 col-md-7 vertical-align">
          <p v-html="$t('use_cases.railway.desc1')"></p>
        </div>
      </div>

      <div class="row justify-content-center py-3">

        <div class="col-xl-8 col-lg-7 col-md-7 order-1 order-md-0 vertical-align">
          <p v-html="$t('use_cases.railway.desc2')"></p>
        </div>

        <div class="col-xl-4 col-lg-5 col-md-5 col-6">
          <div class="ab-img">
            <img src="../../assets/img/case/railway-2.jpg" alt="" class="image-radius-12">
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'RailwayNetwork',
}
</script>