<template>
  <div class="slider-area fix p-relative">
    <div class="slider-active swiper-container ">
        <swiper
        ref="mySwiper"
        :modules="modules"
        :effect="'fade'"
        :loop="true"
        :navigation="{nextEl:'.swiper-button-next', prevEl:'.swiper-button-prev'}"
        :autoplay="{delay: 5000}">
        <swiper-slide v-for="item in sliderData" :key="item.id">
          <div class="slider-list slider-height-3 swiper-slide d-flex align-items-center pt-70"
               :class="sliderNoFilter === true ? 'slider-no-filter' : ''">
          <div class="slider-shape"></div>
          <div class="slider-shape-line"></div>
          <div class="slider-shape-line2"></div>
          <div class="slider-bg img-zoom"
            :style="{ backgroundImage:'url(' + require('../../assets/img/slider/' + item.img) + ')'}"></div>
          <div class="container p-relative">
            <div class="row">
              <div class="col-12 position-relative">
                <div class="slider-circle-shape slider-circle-shape-2"></div>
                <div class="slider-text slider-text-2 z-index">
                  <span class="slider-sub-title">
                    <span v-html="$t(item.subtitle)"></span> {{ productName.toUpperCase() }} - by {{ companyName }}
                  </span >
                  <h2 class="slider-title" v-html="$t(item.title)"> </h2>
<!--                  <div-->
<!--                    class="slide-btn mt-30 d-flex align-items-center">-->
<!--                    <router-link to="#" class="tp-btn">Discover More</router-link>-->
<!--                    <button v-b-modal.modal-center class="play-btn"-->
<!--                      href="https://www.youtube.com/watch?v=eXQgPCsd83c" >-->
<!--                      <i class="fas fa-play"></i>-->
<!--                    </button>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        </swiper-slide>
      </swiper>
      
      <!-- If we need pagination -->
      <!-- <div class="swiper-pagination"></div> -->

      <!-- If we need navigation buttons -->
      <div class="swiper-button-prev sp-arrow">
        <i class="far fa-angle-left"></i>
      </div>
      <div class="swiper-button-next sp-arrow">
        <i class="far fa-angle-right"></i>
      </div>
    </div>
  </div>

  <!-- video modal  start-->
<!--<div>-->
<!--   <div class="video-modal-wrapper">-->
<!--         <b-modal id="modal-center" centered>-->
<!--                <p><iframe src="https://www.youtube.com/embed/LQmTKxI4Wn4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>-->
<!--         </b-modal>-->
<!--    </div>-->
<!--</div>-->
<!-- video modal  end-->

</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, EffectFade, Autoplay } from "swiper";

export default {
  name: "SliderContact",
   components: {
    Swiper,
    SwiperSlide,
  },
  data () {
      return {
          productName: process.env.VUE_APP_NAME,
          companyName: process.env.VUE_APP_COMPANY_NAME,
          // sliderData:[
          //     {
          //         id:'1',
          //         img:'slider3.jpg',
          //         title:'Contact Us'
          //     },
          // ]
      }
  },
   setup() {
    return {
      modules: [Navigation, EffectFade, Autoplay],
    };
  },
  props:{
      sliderData: [Object],
      sliderNoFilter: Boolean,
  }
};
</script>